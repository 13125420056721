export const setBodyHeight = () => {
  let headerHeight = document.querySelector('header').offsetHeight
  let foooterHeight = document.querySelector('footer').offsetHeight
  let absoluteBodyHeight = window.innerHeight - headerHeight

  document.getElementById('page_container').style.height = absoluteBodyHeight + 'px'
  document.querySelector('main').style.minHeight = (absoluteBodyHeight - foooterHeight) + 'px'
}

export const setAbsoluteBodyHeight = ()  => {
  setBodyHeight()
  setTimeout(setBodyHeight, 100)
}
