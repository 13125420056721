<script>
import { setAbsoluteBodyHeight } from '@/supports'

export default {
  // data() {
  //   return {
  //     flagRegionBoxShow: false
  //   }
  // },
  // methods: {
  //   openRegionBox() {
  //     this.flagRegionBoxShow = true
  //   },
  //   closeRegionBox() {
  //     this.flagRegionBoxShow = false
  //   }
  // },
  mounted() {
    // add event resize window
    window.addEventListener('resize', setAbsoluteBodyHeight)
    // mark loaded page
    document.querySelector('html').classList.add('page-loaded')
    document.querySelector('body').classList.add('page-loaded')
    window.pageLoaded = true
    // set absolute body height
    setAbsoluteBodyHeight()
  }
}
</script>